import { useMemo } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { getCountryCodeFromLocale, replaceRouteParam } from '@core/utils/string';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { TableColumnsEnum } from '@core/enums/localeEnum';
import { SearchOperatorsQuery } from '@graphql/generated/graphql';
import { TableConfig, buildOperatorAddress } from '@components/utility/Table/Table.helpers';
import { useAdvancedSearch } from '@core/contexts';

export type OperatorTargetingData = SearchOperatorsQuery['search'];

export const OP_TARGETING_RESULTS_TABLE_TEST_ID = 'operatorTargetingResultsTable';
export const OP_TARGETING_DEFAULT_SORT_FIELD = 'name.keyword';

export function getOperatorResultsTableConfig(
    data: OperatorTargetingData,
    countryLocale: CountryCodesEnum = CountryCodesEnum.US,
    sortConfig: ReturnType<typeof useAdvancedSearch>['advancedSearchSort'],
): TableConfig<TableColumnsEnum> {
    const noDataAvailable = !data || !data?.operators || !data?.operators?.length;
    const operators = data?.operators;

    const rowConfig = {
        anchorHref: (index: ID) => {
            return replaceRouteParam(
                RoutesEnum.OperatorProfile,
                ':id',
                operators?.[index as number]?.operator_identifier ?? '',
            );
        },
    };

    return {
        sortConfig,
        rowConfig,
        config: noDataAvailable
            ? []
            : [
                {
                    title: TableColumnsEnum.Name,
                    field: OP_TARGETING_DEFAULT_SORT_FIELD,
                    Cell: ({ index }) => {
                        return <strong>{operators?.[index]?.business_name?.toUpperCase()}</strong>;
                    },
                    isSortable: true,
                },
                {
                    title: TableColumnsEnum.Address,
                    field: 'address',
                    Cell: ({ index }) => (
                        <>{buildOperatorAddress(operators?.[index]?.location, operators?.[index]?.phone)}</>
                    ),
                    isSortable: false,
                },
                {
                    title: TableColumnsEnum.Segment,
                    field: `segment.name.${countryLocale}.keyword`,
                    Cell: ({ index }) => <>{operators?.[index]?.segment}</>,
                    isSortable: true,
                },
                {
                    title: TableColumnsEnum.Cuisine,
                    field: `cuisine.name.${countryLocale}.keyword`,
                    Cell: ({ index }) => <>{operators?.[index]?.sub_cuisine}</>,
                    isSortable: true,
                },
                {
                    title: TableColumnsEnum.TotalUnits,
                    field: 'number_of_units_in_chain',
                    align: 'right',
                    Cell: ({ index }) => {
                        return (
                            <div style={{ maxWidth: '20rem' }}>
                                {operators?.[index]?.parent_chain?.number_of_units ?? 1}
                            </div>
                        );
                    },
                    isSortable: true,
                },
            ],
    };
}

export default function useOperatorResultsTable(data: OperatorTargetingData): TableConfig<TableColumnsEnum> {
    const { locale } = useLocale();
    const { advancedSearchSort } = useAdvancedSearch();
    const localeCountryCode = getCountryCodeFromLocale(locale);

    return useMemo(
        () => getOperatorResultsTableConfig(data, localeCountryCode, advancedSearchSort),
        [data, localeCountryCode, advancedSearchSort],
    );
}
