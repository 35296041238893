import { FC } from 'react';
import cn from 'classnames';

import { IconArrowDown } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { useSelectInput } from '@core/hooks/useInput';
import { replaceRouteParam } from '@core/utils/string';
import Select from '@components/utility/Inputs/Select/Select';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { useTableContext } from '@components/utility/Table/TableContext';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { buildOperatorAddress } from '@components/utility/Table/Table.helpers';
import TableFeedback from '@components/utility/Table/TableFeedback/TableFeedback';

import { OperatorTargetingData } from '../../hooks/useOperatorResultsTable';
import { useNavigate } from 'react-router-dom';

const OperatorResultsTableMobile: FC<{
    data: OperatorTargetingData;
    testId: string;
    loading: boolean;
}> = ({
    data,
    testId,
    loading
}) => {
    const { t } = useLocale();
    const { config } = useTableContext();
    const navigate = useNavigate();
    const {
        advancedSearchSort: {
            onSort,
            sortedColumns
        },
    } = useAdvancedSearch();
    const {
        value,
        onChange
    } = useSelectInput();

    const selectOptions: SelectOption[] = config
        .filter((column) => column.isSortable)
        .map((column) => ({
            label: t(column.title),
            id: column.field,
        }));

    const sortedColumn = sortedColumns[0];

    return (
        <Widget type={WidgetType.Table} testId={testId}>
            <TableFeedback isLoading={loading} isEmpty={(data?.operators?.length ?? 0) === 0} />
            <div className="OperatorResultsTableMobile__header">
                <Text variant={TextVariant.B2} testId="sortLabel">
                    SORT:
                </Text>
                <div style={{ minWidth: '120px' }}>
                    <Select
                        value={value ?? sortedColumn.field}
                        height="1px"
                        onChange={(value) => {
                            if (value) {
                                onSort(value);
                            }
                            onChange(value);
                        }}
                        options={selectOptions}
                        testId="selectSortedColumn"
                    />
                </div>
                <div
                    onClick={() => onSort(sortedColumn.field)}
                    className={cn('OperatorResultsTableMobile__sort', {
                        'OperatorResultsTableMobile__sort--desc': sortedColumn.order === 'DESC',
                    })}
                    data-testid="tableMobileSortIcon"
                >
                    <IconArrowDown />
                </div>
            </div>
            {data?.operators?.map((row, index) => (
                <a
                    key={index}
                    className="OperatorResultsTableMobile"
                    data-testid={`tableRow${index}`}
                    onClick={() => {
                        navigate(replaceRouteParam(RoutesEnum.OperatorProfile, ':id', row?.operator_identifier ?? ''));
                    }}
                >
                    <div className="OperatorResultsTableMobile__name" data-testid="tableCellName">
                        <strong>{row?.business_name}</strong>
                    </div>
                    {Object.keys(row?.location ?? {}).length > 1 && buildOperatorAddress(row?.location, row?.phone)}

                    {(row?.segment || row?.sub_cuisine) && (
                        <div className="OperatorResultsTableMobile__row">
                            {row?.segment && <div data-testid="tableCellSegment">{row?.segment}</div>}
                            {row?.cuisine && <div data-testid="tableCellCuisine">{row?.sub_cuisine}</div>}
                        </div>
                    )}
                    {!!row?.parent_chain?.number_of_units && row.parent_chain.number_of_units > 0 && (
                        <div className="OperatorResultsTableMobile__row">
                            <div data-testid="tableCellTotalUnits">
                                {`${row.parent_chain?.number_of_units} total unit${
                                    row.parent_chain?.number_of_units > 1 ? 's' : ''
                                }`}
                            </div>
                        </div>
                    )}
                </a>
            ))}
        </Widget>
    );
};

export default OperatorResultsTableMobile;
