import { FC } from 'react';
import MuiTableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';

import { useTableContext } from './TableContext';
import TableCell from './TableCell';
import cn from 'classnames';

const TableRow: FC<{
    rowIndex: number;
}> = ({ rowIndex }) => {
    const {
        config,
        rowConfig
    } = useTableContext();

    return (
        <MuiTableRow
            className={cn('Table__Row', { 'Table__Row--clickable': !!rowConfig?.anchorHref })}
            data-testid={`tableRow${rowIndex}`}
            component={rowConfig?.anchorHref ? Link : 'tr'}
            to={rowConfig?.anchorHref?.(rowIndex) || '#'}
        >
            {config.map((item, colIndex) => {
                const {
                    Cell,
                    align,
                    onClick
                } = item;
                return (
                    <TableCell
                        key={colIndex}
                        align={align}
                        testId={`tableCell${rowIndex}_${colIndex}`}
                        className={cn({ 'Table__Cell--clickable': !!onClick })}
                        onClick={() => {
                            onClick?.(rowIndex);
                        }}
                    >
                        <Cell index={rowIndex} />
                    </TableCell>
                );
            })}
        </MuiTableRow>
    );
};

export default TableRow;
