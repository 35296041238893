import cn from 'classnames';
import MuiTableRow from '@mui/material/TableRow';

import { IconArrowDown } from '@assets/icons';
import { useLocale } from '@core/utils/locale';

import TableCell from './TableCell';
import { useTableContext } from './TableContext';

const TableHead: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const {
        config,
        sortConfig
    } = useTableContext();
    const { t } = useLocale();

    // The primary sort column (visible in the UI) is the first column in the sortedColumns array
    const sortedColumn = sortConfig?.sortedColumns[0];

    return (
        <MuiTableRow className="Table__Row Table__Head" role="rowheader" data-testid="tableHeaderRow">
            {config.map((column, colIndex) => {
                const isSorted = sortedColumn?.field === column.field;
                return (
                    <TableCell
                        onClick={() => {
                            if (column.isSortable) {
                                sortConfig?.onSort(column.field);
                            }
                        }}
                        key={colIndex}
                        align={column.align}
                        className={cn(
                            {
                                'Table__Cell--unsortable': !column.isSortable,
                            },
                            {
                                [`Table__Cell--selected-${sortedColumn?.order}`]: isSorted && column.isSortable,
                            },
                        )}
                        testId={`tableHeaderColumn${colIndex}`}
                    >
                        {t(column.title)}
                        <IconArrowDown />
                    </TableCell>
                );
            })}
            {children}
        </MuiTableRow>
    );
};

export default TableHead;
