import { FC, Fragment, useEffect } from 'react';

import useListenToActiveMenuItem from '@pages/OperatorTargetingCriteria/hooks/useListenToActiveMenuItem';

import useGeneralCriteria from '../../hooks/useGeneralCriteria';
import { GeneralCriteria, RestaurantsAndBarsCriteria } from '../../helpers/Criteria.helpers';
import LodgingAndRecreationCriteria from './LodgingAndRecreation/LodgingAndRecreationCriteria';

const SegmentsCriteriaContent: FC<{
    onUpdateActiveNavigationSubItem: (id: string) => void;
}> = ({ onUpdateActiveNavigationSubItem }) => {
    const { ranges } = useGeneralCriteria();

    const { observe } = useListenToActiveMenuItem(
        onUpdateActiveNavigationSubItem,
        '[data-observable]',
        '#observer-root',
    );

    useEffect(() => {
        if (ranges !== null) {
            setTimeout(() => {
                observe();
            }, 1500);
        }
    }, [observe, ranges]);

    if (!ranges) {
        return null;
    }

    return (
        <Fragment>
            <GeneralCriteria ranges={ranges} />
            <RestaurantsAndBarsCriteria ranges={ranges} />
            <LodgingAndRecreationCriteria ranges={ranges} />
        </Fragment>
    );
};
export default SegmentsCriteriaContent;
