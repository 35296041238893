import { ReactNode } from 'react';

import {
    IconFilterGeneral,
    IconFilterLocation,
    IconFilterLodging,
    IconFilterRestaurants,
    IconFilterSegments,
    IconFilterTags,
} from '@assets/icons';
import { ID } from '@models/index';
import { FilterTypeEnum } from '@core/enums/localeEnum';
import { GradientColorsEnum } from '@enums/gradientEnum';
import { toggleObjectInArrayByKey } from '@core/helpers/helpers';

export type Criterion = {
    id: ID;
    name: FilterTypeEnum;
    baseColor: GradientColorsEnum;
    CriterionParams: CriterionParam[];
};

export type CriterionParam = {
    value: ID;
    name: string;
    filterKey: CriterionParamFilter;
    description?: string;
    isPermanent?: boolean;
    additionalData?: Record<string, unknown>;
};

export enum CriterionParamFilter {
    Country = 'country',
    AdministrativeArea1 = 'administrative_area_1',
    AdministrativeArea2 = 'administrative_area_2',
    Segment = 'segmentation',
    Cuisine = 'cuisine',
    PostalCode = 'postal_code',
    ChainSizeRange = 'chain_size_range',
    AnnualSalesRange = 'annual_sales_range',
    EstMealsPerDayRange = 'est_meals_per_day_range',
    YearsInBusinessRange = 'founded_date',
    NumberOfEmployeesRangeCommercial = 'number_of_employees_range_comercial',
    NumberOfEmployeesRangeNonCommercial = 'number_of_employees_range_noncomercial',
    ConfidenceLevel = 'confidence_level',
    GeoLocation = 'geo_location',
    PotentialPurchaseTotal = 'purchase_potential_total',
    NumberOfRoomsRange = 'number_of_rooms_range',
    HotelStarLevel = 'lodging_star_level',
    FoodTags = 'food_tags',
    PlaceTags = 'place_tags',
    AverageCheckRange = 'average_check_range',
}

export enum CriterionEnum {
    Location = 'Location',
    Segments = 'Segments',
    CuisineTypes = 'CuisineTypes',
    Tags = 'Tags',
    General = 'General',
    LodgingRecreation = 'LodgingRecreation',
}

export enum NoEmployeesCountDataEnum {
    Commercial = 'NoDataCommercial',
    NonCommercial = 'NoDataNonCommercial',
}

export type CriteriaData = {
    [key in CriterionEnum]: Criterion;
};

export function getCriterionEnumIcon(criterionEnum: CriterionEnum): ReactNode {
    switch (criterionEnum) {
        case CriterionEnum.Location:
            return <IconFilterLocation />;
        case CriterionEnum.Segments:
            return <IconFilterSegments />;
        case CriterionEnum.CuisineTypes:
            return <IconFilterRestaurants />;
        case CriterionEnum.Tags:
            return <IconFilterTags />;
        case CriterionEnum.General:
            return <IconFilterGeneral />;
        case CriterionEnum.LodgingRecreation:
            return <IconFilterLodging />;
    }
}

export class CriterionParamsManager {
    static updateCriterionParams(
        criterionParams: CriterionParam[],
        newCriterionParam: CriterionParam,
    ): CriterionParam[] {
        const criterionParamsMapper =
            this.criterionParamsMappers[newCriterionParam.filterKey] || this.toggleCriterionParamMapper;

        return criterionParamsMapper(criterionParams, newCriterionParam);
    }

    static criterionParamsMappers: Partial<
        Record<CriterionParamFilter, (paramsList: CriterionParam[], newParam: CriterionParam) => CriterionParam[]>
    > = {
            country: CriterionParamsManager.countryMapper,
            geo_location: CriterionParamsManager.geoLocationMapper,
        };

    static toggleCriterionParamMapper(paramsList: CriterionParam[], newParam: CriterionParam) {
        const updatedParamsList = toggleObjectInArrayByKey<CriterionParam>(paramsList, newParam, 'value');

        return updatedParamsList;
    }

    static countryMapper(paramsList: CriterionParam[], newParam: CriterionParam) {
        const countryParam = { ...newParam, isPermanent: true };
        const index = paramsList.findIndex((item) => item.filterKey === countryParam.filterKey);

        if (paramsList[index]?.value === countryParam.value) {
            return paramsList;
        }

        return [countryParam];
    }

    static geoLocationMapper(paramsList: CriterionParam[], geoLocationParam: CriterionParam) {
        const geoParam = paramsList.find((item) => item.filterKey === geoLocationParam.filterKey);

        if (!geoParam) {
            return [...paramsList, geoLocationParam];
        }

        if (geoParam.value === geoLocationParam.value) {
            return paramsList;
        }

        const replacedGeoParamFromList = paramsList.map((item) => {
            if (item.filterKey === geoLocationParam.filterKey) {
                return geoLocationParam;
            }

            return item;
        });

        return replacedGeoParamFromList;
    }
}
