import React from 'react';
import cn from 'classnames';

import { HomePhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import QuickSearch, { QuickSearchTypes } from '@components/utility/Inputs/QuickSearch/QuickSearch';

import './Home.styles.scss';

const backgroundVideoSource = `${process.env.PUBLIC_URL}/home-video.mp4`;

const Home: React.FC = () => {
    const [hasAnimated, setHasAnimated] = React.useState<boolean>(false);

    const onAnimate = () => {
        setHasAnimated(true);
    };

    const { t } = useLocale();
    return (
        <div className="Home">
            <div className="Home__overlay"></div>
            <video className="Home__video" src={backgroundVideoSource} autoPlay loop muted playsInline />
            <div className="Home__content">
                <div className={cn('Home__search', { 'Home__search--interacted': hasAnimated })}>
                    <QuickSearch
                        placeholder={t(HomePhrasesEnum.QuickSearchPlaceholder)}
                        type={QuickSearchTypes.LARGE}
                        hasAnimated={hasAnimated}
                        onAnimate={onAnimate}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
