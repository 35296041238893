import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useLocale } from '@utils/locale';
import { ID, iOption } from '@core/models';
import { generateAnchorId } from '@core/utils/string';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { iNavigationItem } from '@components/ui/SearchNavigation/SearchNavigation';
import { IconFilter, IconFilterLocation, IconFilterSegments, IconFilterTags } from '@assets/icons';
import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    TableColumnsEnum,
} from '@enums/localeEnum';

import { LocationContent, SegmentsContent, SegmentsCriteriaContent, TagsContent } from '../helpers/Criteria.helpers';
import useSegmentations from '@core/api/useSegmentation';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { useAdvancedSearch } from '@core/contexts';
import useGeneralCriteria from './useGeneralCriteria';
import { RangesTypesEnum } from '@core/api/useRanges';
import useTagsCriteria from './useTagsCriteria';
import { TagsCategoryEnum } from '@core/api/useTags';

type NavFilter = {
    id: ID;
    testId: string;
    title: string;
};

interface OperatorTargetingNavItem extends iOption {
    testId: string;
    subItems?: OperatorTargetingNavItem[];
    filters?: NavFilter[];
    content?: ReactNode;
    baseColor?: GradientColorsEnum;
    icon?: React.ReactNode;
}

export const FILTER_ID_PREFIX = 'filter-';

function useGetNavigation() {
    const { t } = useLocale();
    const [activeNavigationItemId, setActiveNavigationItemId] = useState<ID>(0);
    const [activeNavigationSubItemId, setActiveNavigationSubItemId] = useState<ID | null>(null);
    const [activeNavigationFilterId, setActiveNavigationFilterId] = useState<ID | null>(null);
    const isPurchasePotEnabled = useFeatureFlag(FeatureFlags.PurchasePotentialFilter);
    const isEstMealsPerDayEnabled = useFeatureFlag(FeatureFlags.EstMealsPerDay);
    const {
        data: segmentations,
        doFetch
    } = useSegmentations();
    const { getCriterionByFilterKey } = useAdvancedSearch();
    const { locale } = useLocale();
    const activeCountryCriterion = getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0];

    const { ranges } = useGeneralCriteria();
    const { tags } = useTagsCriteria();

    const isLodgingEnabled = useFeatureFlag(FeatureFlags.LodgingNav);
    const isNumberOfRoomsEnabled = useFeatureFlag(FeatureFlags.NumberOfRooms);
    const isHotelClassEnabled = useFeatureFlag(FeatureFlags.HotelClass);
    const isTagsFilterEnabled = useFeatureFlag(FeatureFlags.TagsFilter);
    const isAverageCheckEnabled = useFeatureFlag(FeatureFlags.AverageCheck);

    useEffect(() => {
        if (activeCountryCriterion?.value) {
            doFetch({
                country: activeCountryCriterion.value as string,
                locale,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCountryCriterion?.value, doFetch, locale]);

    const segmentNavTypes = useMemo(
        () => [
            FilterTypeEnum.RestaurantsAndBars,
            FilterTypeEnum.LodgingAndRecreation,
            FilterTypeEnum.Retail,
            FilterTypeEnum.NonCommercial,
            FilterTypeEnum.OtherFoodservice,
        ],
        [],
    );

    const testIdMapping: { [key in FilterTypeEnum]?: string } & { [key in OperatorProfilePhrasesEnum]?: string } & {
        [key in OperatorTargetingPhrasesEnum]?: string;
    } = {
        [FilterTypeEnum.LodgingAndRecreation]: 'SidebarSubnavLodgingAndRecreation',
        [FilterTypeEnum.Retail]: 'SidebarSubnavRetail',
        [FilterTypeEnum.NonCommercial]: 'SidebarSubnavNonCommercial',
        [FilterTypeEnum.OtherFoodservice]: 'SidebarSubnavOtherFoodservice',
        [FilterTypeEnum.Location]: 'SidebarNavLocation',
        [FilterTypeEnum.Segments]: 'SidebarNavSegments',
        [FilterTypeEnum.SegmentsCriteria]: 'SidebarNavSegmentsCriteria',
        [FilterTypeEnum.Tags]: 'SidebarNavTags',
        [FilterTypeEnum.General]: 'SidebarSubnavGeneral',
        [FilterTypeEnum.ChainIndependent]: 'SidebarFilterChainIndependent',
        [FilterTypeEnum.Business]: 'SidebarFilterYearsinBusiness',
        [OperatorProfilePhrasesEnum.EstimatedAnnualSales]: 'SidebarFilterEstimatedAnnualSales',
        [FilterTypeEnum.NumberOfEmployees]: 'SidebarFilterNumberOfEmployees',
        [FilterTypeEnum.TotalPurchasePotential]: 'SidebarFilterTotalPurchasePotential',
        [OperatorProfilePhrasesEnum.ConfidenceLevel]: 'SidebarFilterConfidenceLevel',
        [FilterTypeEnum.RestaurantsAndBars]: 'SidebarSubnavRestaurantsAndBarsCuisines',
        [FilterTypeEnum.CuisineTypes]: 'SidebarFilterCuisineTypes',
        [OperatorProfilePhrasesEnum.EstMealsPerDay]: 'SidebarFilterEstMealsPerDayTypes',
        [FilterTypeEnum.Regional]: 'SidebarSubnavRegional',
        [FilterTypeEnum.Radius]: 'SidebarSubnavRadius',
        [FilterTypeEnum.NumberofRooms]: 'SidebarFilterNumberofRooms',
        [OperatorProfilePhrasesEnum.HotelClass]: 'SidebarFilterHotelClass',
        [OperatorProfilePhrasesEnum.FoodCuisine]: 'SidebarSubnavFoodTags',
        [OperatorProfilePhrasesEnum.Place]: 'SidebarSubnavPlaceTags',
        [OperatorTargetingPhrasesEnum.AverageCheck]: 'SidebarFilterAverageCheckRangeType',
    };

    const filterSegmentNavTypes = segmentNavTypes.map((segmentNavType) => ({
        title: t(segmentNavType),
        id: generateAnchorId(t(segmentNavType)),
        testId: testIdMapping[segmentNavType],
    }));

    const filteredSegmentNavList = useMemo(() => {
        return (
            segmentations?.children?.flatMap((segmentation) => {
                return filterSegmentNavTypes.filter((filterObject) => {
                    if (filterObject.title !== segmentation?.description) {
                        return false;
                    }
                    const allSegmentsInactive = segmentation?.children?.every((child) =>
                        child?.children?.every((grandChild) => grandChild.active === false),
                    );

                    return !allSegmentsInactive;
                });
            }) || []
        );
    }, [segmentations, filterSegmentNavTypes]);

    const NAVIGATION: OperatorTargetingNavItem[] = useMemo(() => {
        const items: OperatorTargetingNavItem[] = [
            {
                id: 1,
                title: t(FilterTypeEnum.Location),
                testId: testIdMapping[FilterTypeEnum.Location] || '',
                content: <LocationContent onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem} />,
                icon: <IconFilterLocation />,
                baseColor: GradientColorsEnum.GREEN,
                subItems: [
                    {
                        title: t(FilterTypeEnum.Regional),
                        id: generateAnchorId(t(FilterTypeEnum.Regional)),
                        testId: testIdMapping[FilterTypeEnum.Regional] || '',
                    },
                    {
                        title: t(FilterTypeEnum.Radius),
                        id: generateAnchorId(t(FilterTypeEnum.Radius)),
                        testId: testIdMapping[FilterTypeEnum.Radius] || '',
                    },
                ],
            },
            {
                id: 2,
                title: t(FilterTypeEnum.Segments),
                testId: testIdMapping[FilterTypeEnum.Segments] || '',
                content: <SegmentsContent onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem} />,
                icon: <IconFilterSegments />,
                baseColor: GradientColorsEnum.BLUE,
                subItems: segmentNavTypes
                    .map((segmentNavType) => ({
                        title: t(segmentNavType),
                        id: generateAnchorId(t(segmentNavType)),
                        testId: testIdMapping[segmentNavType] || '',
                    }))
                    .filter((subItem) =>
                        filteredSegmentNavList.some((segmentNav) => segmentNav.title === subItem.title),
                    ),
            },
            {
                id: 3,
                title: t(FilterTypeEnum.SegmentsCriteria),
                content: <SegmentsCriteriaContent onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem} />,
                testId: testIdMapping[FilterTypeEnum.SegmentsCriteria] || '',
                icon: <IconFilter />,
                baseColor: GradientColorsEnum.PURPLE,
                subItems: [
                    {
                        id: generateAnchorId(t(FilterTypeEnum.General)),
                        title: t(FilterTypeEnum.General),
                        testId: testIdMapping[FilterTypeEnum.General] || '',
                        filters: [
                            ...(ranges?.[RangesTypesEnum.ChainSizeRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.ChainIndependent),
                                        id: generateAnchorId(t(FilterTypeEnum.ChainIndependent), FILTER_ID_PREFIX),
                                        testId: testIdMapping[FilterTypeEnum.ChainIndependent] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.YearsInBusinessRangeType]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.YearsinBusiness),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.YearsinBusiness),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: testIdMapping[FilterTypeEnum.Business] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.NumberOfEmployeesCommercialRangeType] &&
                            ranges?.[RangesTypesEnum.NumberOfEmployeesNonCommercialRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.NumberOfEmployees),
                                        id: generateAnchorId(t(FilterTypeEnum.NumberOfEmployees), FILTER_ID_PREFIX),
                                        testId: testIdMapping[FilterTypeEnum.NumberOfEmployees] ?? '',
                                    },
                                ]
                                : []),
                            ...(ranges?.[RangesTypesEnum.AnnualSalesRangeType]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.EstimatedAnnualSales),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.EstimatedAnnualSales),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: testIdMapping[OperatorProfilePhrasesEnum.EstimatedAnnualSales] ?? '',
                                    },
                                ]
                                : []),
                            ...(isPurchasePotEnabled && !!ranges?.[RangesTypesEnum.PurchasePotentialTotalRangeType]
                                ? [
                                    {
                                        title: t(FilterTypeEnum.TotalPurchasePotential),
                                        id: generateAnchorId(
                                            t(FilterTypeEnum.TotalPurchasePotential),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: testIdMapping[FilterTypeEnum.TotalPurchasePotential] ?? '',
                                    },
                                ]
                                : []),

                            {
                                title: t(OperatorProfilePhrasesEnum.ConfidenceLevel),
                                id: generateAnchorId(t(OperatorProfilePhrasesEnum.ConfidenceLevel), FILTER_ID_PREFIX),
                                testId: testIdMapping[OperatorProfilePhrasesEnum.ConfidenceLevel] ?? '',
                            },
                        ],
                    },
                    {
                        id: generateAnchorId(t(FilterTypeEnum.RestaurantsAndBars)),
                        title: t(FilterTypeEnum.RestaurantsAndBars),
                        testId: testIdMapping[FilterTypeEnum.RestaurantsAndBars] ?? '',
                        filters: [
                            {
                                title: t(FilterTypeEnum.CuisineTypes),
                                id: generateAnchorId(t(TableColumnsEnum.Cuisine), FILTER_ID_PREFIX),
                                testId: testIdMapping[FilterTypeEnum.CuisineTypes] ?? '',
                            },
                            ...(ranges?.[RangesTypesEnum.EstMealsPerDayRangeType] && isEstMealsPerDayEnabled
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                        id: generateAnchorId(
                                            t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: testIdMapping[OperatorProfilePhrasesEnum.EstMealsPerDay] ?? '',
                                    },
                                ]
                                : []),

                            ...(ranges?.[RangesTypesEnum.AverageCheckRangeType] && isAverageCheckEnabled
                                ? [
                                    {
                                        title: t(OperatorTargetingPhrasesEnum.AverageCheck),
                                        id: generateAnchorId(
                                            t(OperatorTargetingPhrasesEnum.AverageCheck),
                                            FILTER_ID_PREFIX,
                                        ),
                                        testId: testIdMapping[OperatorTargetingPhrasesEnum.AverageCheck] ?? '',
                                    },
                                ]
                                : []),
                        ],
                    },
                    ...(isNumberOfRoomsEnabled || isLodgingEnabled
                        ? [
                            {
                                id: generateAnchorId(t(FilterTypeEnum.LodgingAndRecreation)),
                                title: t(FilterTypeEnum.LodgingAndRecreation),
                                testId: testIdMapping[FilterTypeEnum.LodgingAndRecreation] ?? '',
                                filters: [
                                    ...(isNumberOfRoomsEnabled
                                        ? [
                                            {
                                                title: t(FilterTypeEnum.NumberofRooms),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.NumberofRooms),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: testIdMapping[FilterTypeEnum.NumberofRooms] ?? '',
                                            },
                                        ]
                                        : []),
                                    ...(isHotelClassEnabled
                                        ? [
                                            {
                                                title: t(OperatorProfilePhrasesEnum.HotelClass),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.HotelClass),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: testIdMapping[OperatorProfilePhrasesEnum.HotelClass] ?? '',
                                            },
                                        ]
                                        : []),
                                    ...(isLodgingEnabled
                                        ? [
                                            {
                                                title: t(OperatorProfilePhrasesEnum.AverageReviewRating),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.AverageReviewRating),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId:
                                                        testIdMapping[OperatorProfilePhrasesEnum.AverageReviewRating] ??
                                                        '',
                                            },
                                            {
                                                title: t(FilterTypeEnum.ServicesOffered),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.ServicesOffered),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: testIdMapping[FilterTypeEnum.ServicesOffered] ?? '',
                                            },
                                            {
                                                title: t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                                id: generateAnchorId(
                                                    t(OperatorProfilePhrasesEnum.EstMealsPerDay),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId:
                                                        testIdMapping[OperatorProfilePhrasesEnum.EstMealsPerDay] ?? '',
                                            },
                                            {
                                                title: t(FilterTypeEnum.EventVenueCapacity),
                                                id: generateAnchorId(
                                                    t(FilterTypeEnum.EventVenueCapacity),
                                                    FILTER_ID_PREFIX,
                                                ),
                                                testId: testIdMapping[FilterTypeEnum.EventVenueCapacity] ?? '',
                                            },
                                        ]
                                        : []),
                                ],
                            },
                        ]
                        : []),
                ],
            },
            ...(isTagsFilterEnabled && tags
                ? [
                    {
                        id: 4,
                        title: t(FilterTypeEnum.Tags),
                        testId: testIdMapping[FilterTypeEnum.Tags] ?? '',
                        content: <TagsContent onUpdateActiveNavigationSubItem={onUpdateActiveNavigationSubItem} />,
                        icon: <IconFilterTags />,
                        baseColor: GradientColorsEnum.ORANGE,
                        subItems: [
                            ...(tags?.[TagsCategoryEnum.FOOD]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.FoodCuisine),
                                        id: generateAnchorId(t(OperatorProfilePhrasesEnum.FoodCuisine)),
                                        testId: testIdMapping[OperatorProfilePhrasesEnum.FoodCuisine] ?? '',
                                    },
                                ]
                                : []),
                            ...(tags?.[TagsCategoryEnum.PLACES]
                                ? [
                                    {
                                        title: t(OperatorProfilePhrasesEnum.Place),
                                        id: generateAnchorId(t(OperatorProfilePhrasesEnum.Place)),
                                        testId: testIdMapping[OperatorProfilePhrasesEnum.Place] ?? '',
                                    },
                                ]
                                : []),
                        ],
                    },
                ]
                : []),
        ];

        function onUpdateActiveNavigationSubItem(id: string) {
            const isFilter = id.includes(FILTER_ID_PREFIX);

            if (isFilter) {
                setActiveNavigationFilterId(id);
                let parentSubItemId;

                items?.forEach((item) => {
                    const parentSubItem = item?.subItems?.find((subItem: OperatorTargetingNavItem) => {
                        return subItem?.filters?.filter((filter) => filter.id === id)[0];
                    });

                    parentSubItemId = parentSubItem?.id;
                });

                if (parentSubItemId) {
                    setActiveNavigationSubItemId(parentSubItemId);
                }

                return;
            }

            setActiveNavigationSubItemId(id);
        }

        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, segmentNavTypes, ranges, isPurchasePotEnabled, isEstMealsPerDayEnabled, filteredSegmentNavList]);

    return {
        NAVIGATION,
        activeNavigationFilterId,
        activeNavigationItemId,
        setActiveNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationSubItemId,
        setActiveNavigationFilterId,
    };
}

export default function useSearchNavigation() {
    const {
        NAVIGATION,
        activeNavigationItemId,
        setActiveNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationSubItemId,
        activeNavigationFilterId,
        setActiveNavigationFilterId,
    } = useGetNavigation();

    const content = useMemo(() => {
        const activeItem = NAVIGATION.find((i) => i.id == activeNavigationItemId);

        return activeItem?.content;
    }, [activeNavigationItemId, NAVIGATION]);

    const sidebarNavigationItems = useMemo(() => {
        const onItemClick = (item: iNavigationItem) => {
            if (item.id === activeNavigationItemId) {
                return;
            }

            const firstSubItem = item?.subItems?.[0];
            const firstFilter = firstSubItem?.filters?.[0]?.id;

            if (firstFilter) {
                setActiveNavigationFilterId(firstFilter);
            }

            setActiveNavigationSubItemId(firstSubItem?.id || 0);
            setActiveNavigationItemId(item.id);
        };

        const onSubItemClick = (subItem: iNavigationItem) => {
            if (!subItem?.filters?.length) {
                return;
            }

            if (subItem.id === activeNavigationSubItemId) {
                return setActiveNavigationSubItemId(0);
            }

            return setActiveNavigationSubItemId(subItem.id);
        };

        return NAVIGATION.map((item) => ({
            ...item,
            onClick: onItemClick,
            isActive: item.id === activeNavigationItemId,
            subItems: (item.subItems || []).map((subItem) => ({
                ...subItem,
                isActive: subItem.id === activeNavigationSubItemId,
                onClick: onSubItemClick,
                filters: subItem?.filters?.map((filter) => ({
                    ...filter,
                    isActive: filter.id === activeNavigationFilterId,
                })),
            })),
        }));
    }, [
        NAVIGATION,
        activeNavigationItemId,
        activeNavigationSubItemId,
        setActiveNavigationItemId,
        setActiveNavigationSubItemId,
        activeNavigationFilterId,
        setActiveNavigationFilterId,
    ]);

    useEffect(() => {
        if (activeNavigationItemId === 0) {
            const firstItem = NAVIGATION[0];
            setActiveNavigationItemId(firstItem.id);
            setActiveNavigationSubItemId(firstItem?.subItems?.[0]?.id || 0);
        }
    }, [NAVIGATION, activeNavigationItemId, setActiveNavigationItemId, setActiveNavigationSubItemId]);

    const onTitleClick = useCallback(() => {
        setActiveNavigationItemId(0);
        setActiveNavigationSubItemId(null);
    }, [setActiveNavigationItemId, setActiveNavigationSubItemId]);

    return {
        content,
        onTitleClick,
        sidebarNavigationItems,
    };
}
