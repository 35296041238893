import { FC, useState } from 'react';

import { ID } from '@core/models';

import './DonutChart.styles.scss';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import { capitalize } from '@core/utils/string';

export type DonutSlice = {
    id: ID;
    value: number;
    percent: number;
    color?: string;
    label?: string;
    onClickCb?: () => void;
};

export type GradientStyles = {
    [key: string]: { startColor: string; endColor: string };
};

type DonutSliceWithCommands = DonutSlice & {
    offset: number;
    commands: string;
};

class CalculusHelper {
    getSlicesWithCommandsAndOffsets(
        donutSlices: DonutSlice[],
        radius: number,
        svgSize: number,
        borderSize: number,
    ): DonutSliceWithCommands[] {
        let previousValue = -45;
        const gapSize = 1;
        const ANGLE_MULTIPLIER = 3.6;
        return donutSlices.map((slice) => {
            const startAngle = (100 - previousValue - slice.percent) * ANGLE_MULTIPLIER + gapSize / 2;
            const endAngle = (100 - previousValue) * ANGLE_MULTIPLIER - gapSize / 2;
            previousValue += slice.percent;
            return {
                ...slice,
                commands: this.getSliceCommands(slice, radius, svgSize, borderSize, startAngle, endAngle),
                offset: 0,
            };
        });
    }

    getSliceCommands(
        donutSlice: DonutSlice,
        radius: number,
        svgSize: number,
        borderSize: number,
        startAngle: number,
        endAngle: number,
    ): string {
        const longPathFlag = endAngle - startAngle > 180 ? 1 : 0;
        const innerRadius = radius - borderSize;

        return [
            `M ${this.getCoordFromDegrees(startAngle, radius, svgSize)}`,
            `A ${radius} ${radius} 0 ${longPathFlag} 0 ${this.getCoordFromDegrees(endAngle, radius, svgSize)}`,
            `L ${this.getCoordFromDegrees(endAngle, innerRadius, svgSize)}`,
            `A ${innerRadius} ${innerRadius} 0 ${longPathFlag} 1 ${this.getCoordFromDegrees(startAngle, innerRadius, svgSize)}`,
        ].join(' ');
    }

    getCoordFromDegrees(angle: number, radius: number, svgSize: number): string {
        const x = Math.cos((angle * Math.PI) / 180);
        const y = Math.sin((angle * Math.PI) / 180);
        return `${x * radius + svgSize / 2} ${y * -radius + svgSize / 2}`;
    }
}

const DEFAULT_STYLES = {
    gradient1: { startColor: '#00D1FF', endColor: '#00EBF5' },
    gradient2: { startColor: '#00B4FF', endColor: '#006EF5' },
    gradient3: { startColor: '#07C7F9', endColor: '#00B4FF' },
};

const DonutChart: FC<{
    data: DonutSlice[];
    radius?: number;
    viewBox?: number;
    borderSize?: number;
    styles?: GradientStyles;
}> = ({
    data,
    radius = 45,
    viewBox = 100,
    borderSize = 16,
    styles = DEFAULT_STYLES
}) => {
    const [hoveredSliceId, setHoveredSliceId] = useState<number | null>(null);

    const calculusHelper = new CalculusHelper();

    return (
        data && (
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className="DonutChart">
                <defs>
                    {Object.entries(styles).map(([key, value]) => (
                        <linearGradient key={key} id={key} x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style={{ stopColor: value.startColor }} />
                            <stop offset="100%" style={{ stopColor: value.endColor }} />
                        </linearGradient>
                    ))}
                </defs>
                {calculusHelper
                    .getSlicesWithCommandsAndOffsets(data, radius, viewBox, borderSize)
                    .map((slice, index) => (
                        <Tooltip
                            key={slice.id}
                            content={capitalize(slice?.label ?? '')}
                            placement="top"
                            disableInteractive
                        >
                            {data.length === 1 ? (
                                <path
                                    key={index}
                                    onClick={() => slice.onClickCb && slice.onClickCb()}
                                    onMouseEnter={() => {
                                        setHoveredSliceId(Number(slice.id));
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredSliceId(null);
                                    }}
                                    className={
                                        hoveredSliceId === null || hoveredSliceId === slice.id
                                            ? 'gradient-path'
                                            : 'non-hovered-slice'
                                    }
                                    fill={`url(#${slice.color})`}
                                    d={`
                                    M ${viewBox / 2} ${viewBox / 2 - radius}
                                    A ${radius} ${radius} 0 1 1 ${viewBox / 2} ${viewBox / 2 + radius}
                                    A ${radius} ${radius} 0 1 1 ${viewBox / 2} ${viewBox / 2 - radius}
                                    Z
                                    M ${viewBox / 2} ${viewBox / 2 - radius + borderSize}
                                    A ${radius - borderSize} ${radius - borderSize} 0 1 0 ${viewBox / 2} ${viewBox / 2 + radius - borderSize}
                                    A ${radius - borderSize} ${radius - borderSize} 0 1 0 ${viewBox / 2} ${viewBox / 2 - radius + borderSize}
                                    Z
                                `}
                                    stroke="white"
                                    strokeWidth={2}
                                    strokeDashoffset={25}
                                >
                                    <title>{slice.label}</title>
                                </path>
                            ) : (
                                <path
                                    key={index}
                                    onClick={() => slice.onClickCb && slice.onClickCb()}
                                    onMouseEnter={() => {
                                        setHoveredSliceId(Number(slice.id));
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredSliceId(null);
                                    }}
                                    className={
                                        hoveredSliceId === null || hoveredSliceId === slice.id
                                            ? 'gradient-path'
                                            : 'non-hovered-slice'
                                    }
                                    fill={`url(#${slice.color})`}
                                    d={slice.commands}
                                    transform={`rotate(${slice.offset})`}
                                    stroke="white"
                                    strokeWidth={2}
                                    strokeDashoffset={25}
                                >
                                    <title>{slice.label}</title>
                                </path>
                            )}
                        </Tooltip>
                    ))}
            </svg>
        )
    );
};

export default DonutChart;
