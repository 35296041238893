import { FC } from 'react';
import cn from 'classnames';
import MuiTableCell from '@mui/material/TableCell';
import { TableConfigItem } from './Table.helpers';
import { TableColumnsEnum } from '@core/enums/localeEnum';

const TableCell: FC<{
    children: React.ReactNode;
    align?: TableConfigItem<TableColumnsEnum>['align'];
    className?: string;
    onClick?: () => void;
    testId?: string;
}> = ({
    children,
    align = 'left',
    className = '',
    onClick,
    testId
}) => {
    return (
        <MuiTableCell
            onClick={onClick}
            className={cn('Table__Cell', { 'Table__Cell--right': align === 'right' }, className)}
            data-testid={testId}
        >
            {children}
        </MuiTableCell>
    );
};

export default TableCell;
